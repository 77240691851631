import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import './App.css';

const Tidepass = () => {

    const [password, setPassword] = useState('');
    const [passphrase, setPassphrase] = useState('');
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [timerSeconds, setTimerSeconds] = useState(20);
    const [passwordClassName, setPasswordClassName] = useState('');
    const [useAlpha, setUseAlpha] = useState(true);
    const [useNumeric, setUseNumeric] = useState(true);
    const [useSymbol, setUseSymbol] = useState(true);
    const [isCopied, setIsCopied] = useState(false);
    const [copiedTimerSeconds, setCopiedTimerSeconds] = useState(5);

    const passwordRef = useRef(null);
    const passPhraseRef = useRef(null);
    const generatedPasswordRef = useRef(null);
    const timeout = 20;

    const generate = () => {
        let outputLength = 15;
        let size = 0;
        let localPassphrase = passphrase;
        const splitIndex = passphrase.indexOf(':');
        if (splitIndex >= 0) {
            const sizeString = passphrase.substring(splitIndex + 1, passphrase.length);
            size = parseInt(sizeString);
            localPassphrase = passphrase.substring(0, splitIndex);        
        }

        const combinedPassPhrase = password + localPassphrase + localPassphrase + password;
        const byteArray = sha256(combinedPassPhrase);
        let generatedPassword = Base64.stringify(byteArray);
        generatedPassword = generatedPassword.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '*');

        if (!useAlpha) {
            generatedPassword = generatedPassword.replace(/[a-zA-Z]/g, '');
        }

        if (!useNumeric) {
            generatedPassword = generatedPassword.replace(/[0-9]/g, '');
        }

        if (!useSymbol) {
            generatedPassword = generatedPassword.replace(/[^0-9a-zA-Z]/g, '');
        }

        if (generatedPassword.length < outputLength) {
            outputLength = generatedPassword.length;
        }

        if (size > 0 && size < outputLength) {
            setGeneratedPassword(generatedPassword.substring(0, size));
        }
        else {
            setGeneratedPassword(generatedPassword.substring(0, outputLength));        
        }
        
        setTimerSeconds(timeout);
    }

    useEffect(() => {
        passwordRef.current.focus();
    }, [])

    useEffect(() => {
        if (password.length > 7) {
            generate();
        } else {
            setGeneratedPassword('Password too short')
        }
    }, [password, passphrase, useAlpha, useNumeric, useSymbol]);

    useEffect(() => {
        checkPasswordComplexity();
    }, [password]);

    useEffect(() => {
        let interval = setInterval(() => {
            if (passphrase !== '') {
                if (timerSeconds === 0) {
                    reset();
                }
                else if (timerSeconds > 0) {
                    setTimerSeconds(timerSeconds => timerSeconds - 1);
                }
            }
        }, 1000);  
        
        return () => clearInterval(interval);
    }, [timerSeconds, passphrase])

    useEffect(() => {
        let interval = setInterval(() => {
            if (isCopied) {
                if (copiedTimerSeconds === 0) {
                    setIsCopied(false);
                    setCopiedTimerSeconds(5);
                    passPhraseRef.current.focus();
                }
                else if (copiedTimerSeconds > 0) {
                    setCopiedTimerSeconds(copiedTimerSeconds => copiedTimerSeconds - 1);
                }
            }
        }, 1000);  
        
        return () => clearInterval(interval);
    }, [copiedTimerSeconds, isCopied])

    const reset = () =>  {
        setPassphrase('');
        passPhraseRef.current.focus();
    }

    const onTextChange = (event) => {
        switch(event.target.name) {
            case 'password':
                setPassword(event.target.value);                
                break;
            case 'passphrase':
                setPassphrase(event.target.value);
                break;
            default:
        }
    }

    const onCheckChange = (event) => {
        switch(event.target.id) {
            case 'chkAlpha':
                setUseAlpha(event.target.checked);
                break;
            case 'chkNumeric':
                setUseNumeric(event.target.checked);
                break;
            case 'chkSymbol':
                setUseSymbol(event.target.checked);
                break;
            default:
        }
    }

    const checkPasswordComplexity = () => {
        if (password.length < 8) {
            setPasswordClassName('bg-pink');
        }
        else {
            let numbers = 0;
            let uppers = 0;
            let lowers = 0;

            for (var i=0; i<password.length; i++) {
                var char = password[i];
                
                if (/^\d+$/.test(char)) {
                    numbers++;
                }
                
                if (/^[A-Z]+$/.test(char)) { 
                    uppers++;
                }

                if (/^[a-z]+$/.test(char)) {
                    lowers++;
                }
            }

            if (numbers > 0 && uppers > 0 && lowers > 0) {
                setPasswordClassName('bg-green');
            } else if (lowers > 0 && (numbers > 0 || uppers > 0)) {
                setPasswordClassName('bg-orange');
            } else {
                setPasswordClassName('bg-yellow');
            }
        }
    }

    const onCopyClick = (event) => {
        generatedPasswordRef.current.select();
        document.execCommand('copy');
        setIsCopied(true);
    }

    return(
        <div className="container">
            <div style={{width: "500px"}} className="border rounded pt-3 pl-3 pr-3 pb-0">
                <Form>
                    <Form.Group as={Row} >
                        <Form.Label column sm="6">Hard to guess passphrase</Form.Label>
                        <Col sm="6"><Form.Control className={passwordClassName} type="password" size="sm" name="password" value={password} onChange={onTextChange} ref={passwordRef} /></Col>
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Form.Label column sm="6">Passphrase name</Form.Label>
                        <Col sm="6"><Form.Control type="text" size="sm" name="passphrase" value={passphrase} onChange={onTextChange} autoComplete="off" ref={passPhraseRef} /></Col>
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Form.Label column sm="6">Generated password</Form.Label>
                        <Col sm="6"><Form.Control type="text" size="sm" value={generatedPassword} readOnly ref={generatedPasswordRef} /></Col>
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Form.Label column sm="6">Complexity</Form.Label>
                        <Col sm="6" className="mt-1">
                            <Form.Check type="checkbox" id="chkAlpha" inline label="a-z" checked={useAlpha} onChange={onCheckChange} />
                            <Form.Check type="checkbox" id="chkNumeric" inline label="0-9" checked={useNumeric} onChange={onCheckChange} />
                            <Form.Check type="checkbox" id="chkSymbol" inline label="Sym" checked={useSymbol} onChange={onCheckChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{minHeight: "50px"}}>
                        <Col sm="6">
                            <Button sm="6" block variant="primary" onClick={onCopyClick}>Copy</Button>
                        </Col>
                        <div className={!isCopied ? 'd-none' : 'ml-3'} style={{color: "black", fontWeight: 'bold'}}>Copied to clipboard</div>
                    </Form.Group>
                    
                </Form>
            </div>
        </div>
    )
}

export default Tidepass;